// Author: Taha HAOULANI: haoulani@amazon.fr
// Date: 31/05/2023

import React, { useState, useRef } from "react";
import { Form, Button, Modal, Spinner } from "react-bootstrap";
import { pdfjs } from "react-pdf";
import { jsPDF } from "jspdf";
import SignatureCanvas from "react-signature-canvas";
import "./App.css"; // Import the CSS file
import "bootstrap/dist/css/bootstrap.min.css";
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

// Configure the AWS credentials and region
AWS.config.update({
  accessKeyId: process.env.REACT_APP_CLIENT_ID,
  secretAccessKey: process.env.REACT_APP_CLIENT_SECRET,
  region: 'eu-west-1',
});

// Create an S3 instance
const s3 = new AWS.S3();
// Create a new instance of the SES service
const ses = new AWS.SES({ region: 'eu-west-1', profile: 'default' });

// CSS styles for the header band
const headerStyles = {
  backgroundColor: "#f8f9fa",
  padding: "20px",
  marginBottom: "20px",
};

// CSS styles for the logo
const logoStyles = {
  maxWidth: "150px",
};

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FormToPDF = () => {
  const [formData, setFormData] = useState({
    employeelastname: '',
    employeeLogin: '',
    employeefirstname: '',
    employerName: '',
    employerPropositions: '',
    dischargeMotive: '',
    dischargeStatus: '',
    nameEmployeeRelative: '',
    occupation: '',
    socioProfessionalCategory: '',
    email: ''
  });

  const [formDataVto, setFormDataVto] = useState({
    employeelastnameVto: '',
    employeeLoginVto: '',
    employeefirstnameVto: '',
    employerNameVto: '',
    employerPropositionsVto: '',
    socioProfessionalCategoryVto: '',
    emailVto: ''
  });

  const emailInputRef = useRef(null);
  const emailInputRefVto = useRef(null);

  const [loading, setLoading] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalSuccessVto, setShowModalSuccessVto] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [showModalErrorVto, setShowModalErrorVto] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formErrorVto, setFormErrorVto] = useState(false);

  const employerSignatureRef = React.useRef(null);
  const employeeSignatureRef = React.useRef(null);
  const employeeSignatureRefVto = React.useRef(null);
  const employeeRelativeSignatureRef = React.useRef(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setFormError(false);
  };

  const handleChangeVto = (event) => {
    const { name, value } = event.target;
    setFormDataVto({ ...formDataVto, [name]: value });
    setFormErrorVto(false);
  };

  const handleClearEmployerSignature = () => {
    employerSignatureRef.current.clear();
  };

  const handleClearEmployeeSignature = () => {
    employeeSignatureRef.current.clear();
  };

  const handleClearEmployeeSignatureVto = () => {
    employeeSignatureRefVto.current.clear();
  };

  const handleClearEmployeeRelativeSignature = () => {
    employeeRelativeSignatureRef.current.clear();
  };

  const getCurrentDateTime = () => {
    const currentDateTime = new Date();
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    return currentDateTime.toLocaleString("en-GB", options);
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleGeneratePDFVto = async () => {
    const {
      employeefirstnameVto,
      employeelastnameVto,
      employeeLoginVto,
      emailVto,
      employerPropositionsVto,
      employerNameVto,
      socioProfessionalCategoryVto
    } = formDataVto;

    setLoading(true);
    // Simulating an asynchronous action with setTimeout
    setTimeout(() => {
      setLoading(false);
    }, 2800);

    // Validate the email field
    if (!formDataVto.emailVto || !isValidEmail(formDataVto.emailVto)) {
      setFormErrorVto(true);
      emailInputRefVto.current.focus(); // Focus the email input field if there is an error
      return;
    }

    const templateVTOURL = process.env.PUBLIC_URL + "/assets/templateVTO.pdf"; //TODO: Variablize in env variable
    const templateVTO = await fetch(templateVTOURL).then((res) => res.arrayBuffer());
    const pdfDocVTO = await pdfjs.getDocument(templateVTO).promise;
    const pageNum = 1; // Change this if your template has multiple pages

    const pageVTO = await pdfDocVTO.getPage(pageNum);

    const canvas = document.createElement("canvas");
    const scale = 2;
    const viewport = pageVTO.getViewport({ scale });

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const canvasContext = canvas.getContext("2d");
    const renderContext = {
      canvasContext,
      viewport,
    };

    await pageVTO.render(renderContext).promise;

    const image = canvas.toDataURL("image/png");
    const docVTO = new jsPDF();
    docVTO.setFontSize(10);

    docVTO.addImage(image, "PNG", 10, 10, 190, 0);
    // Identity Bloc
    docVTO.text(`${employeefirstnameVto} ${employeelastnameVto}`, 70, 97);

    // Socio pro category bloc
    if (`${socioProfessionalCategoryVto}` === "interim") {
      formDataVto['socioProfessionalCategoryVto'] = "Intérimaire";
      docVTO.text(`X`, 79, 57);
    } else if (`${socioProfessionalCategoryVto}` === "fulltime") {
      formDataVto['socioProfessionalCategoryVto'] = "Salarié(e)";
      docVTO.text(`X`, 108, 57);
    }

    // Employer proposals
    if (`${employerPropositionsVto}` === "compensationVto") {
      formDataVto['employerPropositionsVto'] = "VTO recup.";
      docVTO.text(`X`, 39, 134);
    } else if (`${employerPropositionsVto}` === "unpaidLeaveVto") {
      formDataVto['employerPropositionsVto'] = "VTO sans solde.";
      docVTO.text(`X`, 39, 160);
    }

    // Signature bloc
    const employeeSignatureDataVto = employeeSignatureRefVto?.current.toDataURL();
    docVTO.addImage(`${employeeSignatureDataVto}`, 95, 208, 40, 20);

    docVTO.text(`${employeefirstnameVto} ${employeelastnameVto}`, 95, 210);
    const currentDateTime = getCurrentDateTime();
    const currentDate = getCurrentDate();
    docVTO.text(currentDateTime, 95, 195);
    docVTO.text(currentDateTime, 100, 103);

    try {

    // Save the PDF as a buffer
    const pdfBuffer = docVTO.output('arraybuffer');

    const userId = uuidv4();

    const bucketNamePDF = process.env.REACT_APP_S3_BUCKET_PDF;
    const fileNameDa = `${userId}-${currentDate}-${employeeLoginVto}-VTO.pdf`;

    // Upload the PDF to S3
    await uploadFileToS3(bucketNamePDF, fileNameDa, pdfBuffer, 'application/pdf');
  
    // Generate a pre-signed URL for the uploaded file
    const signedUrl = generatePresignedURL(bucketNamePDF, fileNameDa);

    // Send the raw email using SES
    await sendEmailWithPresignedURLVTO(`${emailVto}`, signedUrl);

    // Send data to dynamoDB table
    // Create a new instance of the DynamoDB DocumentClient
    const docClient = new AWS.DynamoDB.DocumentClient();

    // Define the DynamoDB table name
    const tableName = process.env.REACT_APP_ORY_4_DYNAMO_DB;

    // Create the formData object with the generated user ID
    const dynamoDbData = {
      user_id: userId,
      Login: formDataVto['employeeLoginVto'],
      Date: currentDateTime,
      Prenom: formDataVto['employeefirstnameVto'],
      Nom: formDataVto['employeelastnameVto'],
      Manager: formDataVto['employerNameVto'],
      Propositions: formDataVto['employerPropositionsVto'],
      CSP: formData['socioProfessionalCategoryVto'],
      Email_amzn: formDataVto['emailVto']
    };

    // Create the params object for the DynamoDB `put` operation
    const params = {
      TableName: tableName,
      Item: dynamoDbData
    };

    // Insert the formData as a new row in the DynamoDB table
    docClient.put(params, (err, data) => {
      if (err)  {
        console.error('Error inserting data:', err);
      }
    });


    // Show the success modal
    setShowModalSuccessVto(true);
    setFormDataVto({
      employeelastnameVto: '',
      employeeLoginVto: '',
      employeefirstnameVto: '',
      employerNameVto: '',
      employerPropositionsVto: '',
      emailVto: '',
      socioProfessionalCategoryVto: ''
    });

    // Display a success message or perform any additional actions
    } catch (error) {
      // Show the error modal
      setShowModalErrorVto(true);
      console.error('Error when generating documents:', error);
    }
  };

  const handleGeneratePDFDa = async () => {
    const {
      employeefirstname,
      employeelastname,
      occupation,
      employeeLogin,
      email,
      dischargeMotive,
      dischargeStatus,
      socioProfessionalCategory,
      employerPropositions,
      nameEmployeeRelative,
      employerName
    } = formData;

    setLoading(true);
    // Simulating an asynchronous action with setTimeout
    setTimeout(() => {
      setLoading(false);
    }, 2800);

    // Validate the email field
    if (!formData.email || !isValidEmail(formData.email)) {
      setFormError(true);
      emailInputRef.current.focus(); // Focus the email input field if there is an error
      return;
    }

    const templateDaURL = process.env.PUBLIC_URL + "/assets/templateDA.pdf"; //TODO: Variablize in env variable
    const templateDa = await fetch(templateDaURL).then((res) => res.arrayBuffer());
    const pdfDocDa = await pdfjs.getDocument(templateDa).promise;
    const pageNum = 1; // Change this if your template has multiple pages

    const pageDa = await pdfDocDa.getPage(pageNum);

    const canvas = document.createElement("canvas");
    const scale = 2;
    const viewport = pageDa.getViewport({ scale });

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const canvasContext = canvas.getContext("2d");
    const renderContext = {
      canvasContext,
      viewport,
    };
    console.log(renderContext)

    await pageDa.render(renderContext).promise;

    const image = canvas.toDataURL("image/png");
    const docDa = new jsPDF();
    docDa.setFontSize(10);

    docDa.addImage(image, "PNG", 10, 10, 190, 0);
    // Identity Bloc
    docDa.text(`${employeefirstname} ${employeelastname}`, 70, 99);
    docDa.text(`${occupation}`, 63, 108);

    // Socio pro category bloc
    if (`${socioProfessionalCategory}` === "interim") {
      formData['socioProfessionalCategory'] = "Intérimaire";
      docDa.text(`X`, 124, 50);
    } else if (`${socioProfessionalCategory}` === "fulltime") {
      formData['socioProfessionalCategory'] = "Salarié(e)";
      docDa.text(`X`, 148, 50);
    }

    // Discharge status bloc
    if (`${dischargeStatus}` === "waitingDocument") {
      formData['dischargeStatus'] = "En attente de justificatif";
      docDa.text(`X`, 124, 66);
    } else if (`${dischargeStatus}` === "ssoManagerAcceptation") {
      formData['dischargeStatus'] = "Acceptation SSO Manager";
      docDa.text(`X`, 124, 71);
    } else if (`${dischargeStatus}` === "recupManagerAcceptation") {
      formData['dischargeStatus'] = "Acceptation RECUP Manager";
      docDa.text(`X`, 124, 75);
    }

    // Discharge motives bloc
    if (`${dischargeMotive}` === "healthProblem") {
      formData['dischargeMotive'] = "Un problème de santé";
      docDa.text(`X`, 33, 121);
    } else if (`${dischargeMotive}` === "personalMotiveExplained") {
      formData['dischargeMotive'] = "Un motif personnel expliqué à l'entreprise";
      docDa.text(`X`, 33, 126);
    } else if (`${dischargeMotive}` === "personalMotiveNotExplained") {
      formData['dischargeMotive'] = "Un motif personnel non expliqué à l’entreprise";
      docDa.text(`X`, 33, 130);
    } else if (`${dischargeMotive}` === "carpool") {
      formData['dischargeMotive'] = "Co-voiturage";
      docDa.text(`X`, 33, 135);
    }

    // Employer proposals
    if (`${employerPropositions}` === "workstationLayout") {
      formData['employerPropositions'] = "Un poste aménagé pour continuer à travailler jusqu’à la fin de poste";
      docDa.text(`X`, 33, 152);
    } else if (`${employerPropositions}` === "callToRelative") {
      formData['employerPropositions'] = "L’appel d’un proche pour venir me chercher";
      docDa.text(`X`, 33, 157);
    }

    // Signature bloc
    const employerSignatureData = employerSignatureRef?.current.toDataURL();
    const employeeSignatureData = employeeSignatureRef?.current.toDataURL();
    const employeeRelativeSignatureData = employeeRelativeSignatureRef?.current.toDataURL();
    docDa.addImage(`${employerSignatureData}`, 47, 208, 40, 20);
    docDa.addImage(`${employeeSignatureData}`, 95, 208, 40, 20);
    docDa.addImage(`${employeeRelativeSignatureData}`, 140, 208, 40, 20);

    docDa.text(`${employerName}`, 52, 210);
    docDa.text(`${employeefirstname} ${employeelastname}`, 95, 210);
    docDa.text(`${nameEmployeeRelative}`, 140, 210);
    const currentDateTime = getCurrentDateTime();
    const currentDate = getCurrentDate();
    docDa.text(currentDateTime, 95, 195);

    try {

    // Save the PDF as a buffer
    const pdfBuffer = docDa.output('arraybuffer');

    const userId = uuidv4();

    const bucketNamePDF = process.env.REACT_APP_S3_BUCKET_PDF;
    const fileNameDa = `${userId}-${currentDate}-${employeeLogin}-DA.pdf`;

    // Upload the PDF to S3
    await uploadFileToS3(bucketNamePDF, fileNameDa, pdfBuffer, 'application/pdf');
  
    // Generate a pre-signed URL for the uploaded file
    const signedUrl = generatePresignedURL(bucketNamePDF, fileNameDa);

    // Send the raw email using SES
    await sendEmailWithPresignedURLDa(`${email}`, signedUrl);

    // Send data to dynamoDB table
    // Create a new instance of the DynamoDB DocumentClient
    const docClient = new AWS.DynamoDB.DocumentClient();

    // Define the DynamoDB table name
    const tableName = process.env.REACT_APP_ORY_4_DYNAMO_DB;

    // Create the formData object with the generated user ID
    const dynamoDbData = {
      user_id: userId,
      Login: formData['employeeLogin'],
      Date: currentDateTime,
      Prenom: formData['employeefirstname'],
      Nom: formData['employeelastname'],
      Manager: formData['employerName'],
      Propositions: formData['employerPropositions'],
      Motif: formData['dischargeMotive'],
      Statut: formData['dischargeStatus'],
      Proche: formData['nameEmployeeRelative'],
      Poste: formData['occupation'],
      CSP: formData['socioProfessionalCategory'],
      Email_amzn: formData['email']
    };

    // Create the params object for the DynamoDB `put` operation
    const params = {
      TableName: tableName,
      Item: dynamoDbData
    };

    // Insert the formData as a new row in the DynamoDB table
    docClient.put(params, (err, data) => {
      if (err)  {
        console.error('Error inserting data:', err);
      }
    });


    // Show the success modal
    setShowModalSuccess(true);
    setFormData({
      employeelastname: '',
      employeeLogin: '',
      employeefirstname: '',
      employerName: '',
      employerPropositions: '',
      dischargeMotive: '',
      dischargeStatus: '',
      nameEmployeeRelative: '',
      occupation: '',
      socioProfessionalCategory: '',
      email: ''
    });

    // Display a success message or perform any additional actions
    } catch (error) {
      // Show the error modal
      setShowModalError(true);
      console.error('Error when generating documents:', error);
    }
  };

  const handleCloseModalSuccess = () => {
    employerSignatureRef.current.clear();
    employeeRelativeSignatureRef.current.clear();
    employeeSignatureRef.current.clear();
    setShowModalSuccess(false);
    window.scrollTo(0, 0); // Scroll to top of the page
  };

  const handleCloseModalError = () => {
    setShowModalError(false);
    window.scrollTo(0, 0); // Scroll to top of the page

  };

  const handleCloseModalSuccessVto = () => {
    employeeSignatureRefVto.current.clear();
    setShowModalSuccessVto(false);
    window.scrollTo(0, 0); // Scroll to top of the page
  };

  const handleCloseModalErrorVto = () => {
    setShowModalErrorVto(false);
    window.scrollTo(0, 0); // Scroll to top of the page

  };

  // Function to generate a pre-signed URL for the uploaded file
  function generatePresignedURL(bucketName, fileName) {
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Expires: 604800, // 7 days in seconds
    };

    const signedUrl = s3.getSignedUrl('getObject', params);
    return signedUrl;
  }

  // Function to upload a file to S3
  async function uploadFileToS3(bucketName, fileName, fileContent, contentType) {
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: fileContent,
      ContentType: contentType
    };

    const uploadResult = await s3.upload(params).promise();
    return uploadResult.Location;
  }

  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  async function sendEmailWithPresignedURLDa(email, url) {
    const subject = 'Décharge de responsabilité de l’entreprise Amazon.fr Logistique, site de ORY4, lors d’un départ non planifié d’un salarié(e) /intérimaire';
    const message = `Bonjour,\n\nCi-dessous l'url pour télécharger le document pdf de décharge de responsabilité de l'entrepriste Amazon.fr Logistique, site ORY4 (lien valable pendant 7 jours): ${url}\n\nCordialement`;
  
    const params = {
      Destination: {
        ToAddresses: [email],
      },
      Message: {
        Body: {
          Text: { Data: message },
        },
        Subject: { Data: subject },
      },
      Source: process.env.REACT_APP_SENDER_EMAIL,
    };
  
    try {
      await ses.sendEmail(params).promise();
    } catch (error) {
      console.error('Error sending email:', error);
    }
  }

  async function sendEmailWithPresignedURLVTO(email, url) {
    const subject = 'Départ anticipé pour manque d’activité de l’entreprise Amazon.fr Logistique, site de ORY4, lors d’un départ non planifié d’un salarié(e) /intérimaire';
    const message = `Bonjour,\n\nCi-dessous l'url pour télécharger le document pdf de départ anticipé pour manque d'activité de l'entrepriste Amazon.fr Logistique, site ORY4 (lien valable pendant 7 jours): ${url}\n\nCordialement`;
  
    const params = {
      Destination: {
        ToAddresses: [email],
      },
      Message: {
        Body: {
          Text: { Data: message },
        },
        Subject: { Data: subject },
      },
      Source: process.env.REACT_APP_SENDER_EMAIL,
    };
  
    try {
      await ses.sendEmail(params).promise();
    } catch (error) {
      console.error('Error sending email:', error);
    }
  }

  return (
    <div className="form-container">
      <Router>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <NavLink to="/da" className="nav-link" activeClassName="active-link">DA</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/vto" className="nav-link" activeClassName="active-link">VTO</NavLink>
          </li>
        </ul>
      </nav>
  
        <div className="form-content">
          <Routes>
            <Route path="/" element={<Navigate replace to="/da" />} />
            <Route path="/da" element={
              <div>
                <div style={headerStyles}>
                  <img src={process.env.PUBLIC_URL + "/Amazon-logo.png"} alt="Logo" style={logoStyles} />
                  ORY4
                  <div class="right-element"><strong>Amazon France Logistique SAS</strong> 
                    <p>Site de Brétigny-sur-Orge</p>
                  </div>
                </div>
                <h3 className="mb-3">Formulaire de décharge de responsabilité lors d’un départ non planifié</h3>
                <Form>
                  <Form.Group controlId="employeelastname" className="mb-3">
                    <Form.Label htmlFor="employeelastname">Nom du salarié(e):</Form.Label>
                    <Form.Control
                      type="text"
                      name="employeelastname"
                      value={formData.employeelastname}
                      onChange={handleChange}
                      required
                      autoComplete="nope"
                    />
                  </Form.Group>
        
                  <Form.Group controlId="employeefirstname" className="mb-3">
                    <Form.Label htmlFor="employeefirstname">Prénom du salarié(e):</Form.Label>
                    <Form.Control
                      type="text"
                      name="employeefirstname"
                      value={formData.employeefirstname}
                      onChange={handleChange}
                      required
                      autoComplete="nope"
                    />
                  </Form.Group>
        
                  <Form.Group controlId="occupation" className="mb-3">
                    <Form.Label htmlFor="occupation">Occupation du salarié(e):</Form.Label>
                    <Form.Control
                      type="text"
                      name="occupation"
                      value={formData.occupation}
                      onChange={handleChange}
                      required
                      autoComplete="nope"
                    />
                  </Form.Group>
        
                  <Form.Group controlId="employeeLogin" className="mb-3">
                    <Form.Label htmlFor="employeeLogin">Login du salarié(e):</Form.Label>
                    <Form.Control
                      type="text"
                      name="employeeLogin"
                      value={formData.employeeLogin}
                      onChange={handleChange}
                      required
                      autoComplete="nope"
                    />
                  </Form.Group>
        
                  <Form.Group controlId="socioProfessionalCategory" className="mb-3">
                    <Form.Label htmlFor="socioProfessionalCategory">Catégorie:</Form.Label>
                    <Form.Control
                      as="select"
                      name="socioProfessionalCategory"
                      value={formData.socioProfessionalCategory}
                      onChange={handleChange}
                      placeholder="Select your socio"
                      required
                    >
                      <option value="">--</option>
                      <option value="interim">Intérimaire</option>
                      <option value="fulltime">Salarié(e)</option>
                    </Form.Control>
                  </Form.Group>
        
                  <Form.Group controlId="dischargeStatus" className="mb-3">
                    <Form.Label htmlFor="dischargeStatus">Statut de la demande:</Form.Label>
                    <Form.Control
                      as="select"
                      name="dischargeStatus"
                      value={formData.dischargeStatus}
                      onChange={handleChange}
                      placeholder="Select your discharge status"
                      required
                    >
                      <option value="">--</option>
                      <option value="waitingDocument">En attente de justificatif</option>
                      <option value="ssoManagerAcceptation">
                        Acceptation SSO Manager
                      </option>
                      <option value="recupManagerAcceptation">
                        Acceptation RECUP Manager
                      </option>
                    </Form.Control>
                  </Form.Group>
        
                  <Form.Group controlId="dischargeMotive" className="mb-3">
                    <Form.Label htmlFor="dischargeMotive">Motif :</Form.Label>
                    <Form.Control
                      as="select"
                      name="dischargeMotive"
                      value={formData.dischargeMotive}
                      onChange={handleChange}
                      placeholder="Select your discharge motive"
                      required
                    >
                      <option value="">--</option>
                      <option value="healthProblem">Un problème de santé</option>
                      <option value="personalMotiveExplained">
                        Un motif personnel expliqué à l'entreprise
                      </option>
                      <option value="personalMotiveNotExplained">
                        Un motif personnel non expliqué à l’entreprise
                      </option>
                      <option value="carpool">Co-voiturage</option>
                    </Form.Control>
                  </Form.Group>
        
                  <Form.Group controlId="employerPropositions" className="mb-3">
                    <Form.Label htmlFor="employerPropositions">Je reconnais avoir reçu la proposition suivante:</Form.Label>
                    <Form.Control
                      as="select"
                      name="employerPropositions"
                      value={formData.employerPropositions}
                      onChange={handleChange}
                      placeholder="Select your empoloyer props"
                      required
                    >
                      <option value="">--</option>
                      <option value="workstationLayout">
                        Un poste aménagé pour continuer à travailler jusqu’à la fin de
                        poste
                      </option>
                      <option value="callToRelative">
                        L’appel d’un proche pour venir me chercher
                      </option>
                    </Form.Control>
                  </Form.Group>
        
                  <Form.Group controlId="nameEmployeeRelative" className="mb-3">
                    <Form.Label htmlFor="nameEmployeeRelative">Nom et prénom du proche du salarié(e):</Form.Label>
                    <Form.Control
                      type="text"
                      name="nameEmployeeRelative"
                      value={formData.nameEmployeeRelative}
                      onChange={handleChange}
                      required
                      autoComplete="nope"
                    />
                  </Form.Group>
        
                  <Form.Group controlId="employerName" className="mb-3">
                    <Form.Label htmlFor="employerName">Nom et Prénom pour Amazon France Logistique SAS:</Form.Label>
                    <Form.Control
                      type="text"
                      name="employerName"
                      value={formData.employerName}
                      onChange={handleChange}
                      required
                      autoComplete="nope"
                    />
                  </Form.Group>
        
                  <Form.Group controlId="email" className="mb-3">
                    <Form.Label htmlFor="email">Email pour Amazon France Logistique SAS:</Form.Label>
                    <Form.Control
                      as="select"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      ref={emailInputRef}
                      autoComplete="nope"
                    >
                      <option value="">Choisissez un email</option>
                      <option value="ory4-hrap@amazon.com">ory4-hrap@amazon.com</option>
                      <option value="adecco.dc1@onsite-solutions.fr">adecco.dc1@onsite-solutions.fr</option>
                    </Form.Control>
                    <small className="text-muted">Ou entrez votre propre email:</small>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      autoComplete="nope"
                    />
                    {formError && !formData.email && !formData.customEmail && (
                      <div className="error-message">Choisissez un email ou saisissez le vôtre</div>
                    )}
                  </Form.Group>
        
                  <Form.Group controlId="employerSignature" className="inline-form-group">
                    <Form.Label className="form-label" htmlFor="employerSignature">Pour Amazon France Logistique SAS:</Form.Label>
                    <SignatureCanvas
                      ref={employerSignatureRef}
                      canvasProps={{
                        width: 500,
                        height: 200,
                        className: "employer-signature-canvas"
                      }}
                      id="employer-signature-canvas"
                    />
                    <div>
                      <Button
                        variant="outline-secondary"
                        onClick={handleClearEmployerSignature}
                      >
                        Effacer Signature
                      </Button>
                    </div>
                  </Form.Group>
        
                  <Form.Group controlId="employeeSignature" className="inline-form-group">
                    <Form.Label className="form-label" htmlFor="employeeSignature">Pour salarié(e):</Form.Label>
                    <SignatureCanvas
                      ref={employeeSignatureRef}
                      canvasProps={{
                        width: 500,
                        height: 200,
                        className: "employee-signature-canvas",
                      }}
                      id="employee-signature-canvas"
                    />
                    <div>
                      <Button
                        variant="outline-secondary"
                        onClick={handleClearEmployeeSignature}
                      >
                        Effacer Signature
                      </Button>
                    </div>
                  </Form.Group>
        
                  <Form.Group controlId="employeeRelativeSignature" className="inline-form-group">
                    <Form.Label className="form-label" htmlFor="employeeRelativeSignature">Pour le proche du salarié(e):</Form.Label>
                    <SignatureCanvas
                      ref={employeeRelativeSignatureRef}
                      canvasProps={{
                        width: 500,
                        height: 200,
                        className: "employee-relative-signature-canvas",
                      }}
                      id="employeeRelativesignature-canvas"
                    />
                    <div>
                      <Button
                        variant="outline-secondary"
                        onClick={handleClearEmployeeRelativeSignature}
                      >
                        Effacer Signature
                      </Button>
                    </div>
                  </Form.Group>
                  <div class="wrapper">
                    <Button variant="primary" type="button" onClick={handleGeneratePDFDa}>
                      Générer mon document
                    </Button>
                    {/* Conditional rendering of the spinner */}
                    {loading && <Spinner animation="border"/>}   
                  </div>
                </Form>
        
                {/* Modal for success message */}
                <Modal show={showModalSuccess} onHide={handleCloseModalSuccess} className="form-modal">
                  <Modal.Header closeButton>
                    <Modal.Title>Email envoyé</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Un email avec une copie du document a été envoyé au responsable.</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModalSuccess}>
                      Fermer
                    </Button>
                  </Modal.Footer>
                </Modal>
        
                {/* Modal for error message */}
                <Modal show={showModalError} onHide={handleCloseModalError} className="form-modal">
                  <Modal.Header closeButton>
                    <Modal.Title>Email non envoyé</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Une erreur technique est survenue. Merci de réessayer ultérieurement.</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModalError}>
                      Fermer
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            } />
            <Route path="/vto" element={
              <div>
                <div style={headerStyles}>
                  <img src={process.env.PUBLIC_URL + "/Amazon-logo.png"} alt="Logo" style={logoStyles} />
                  ORY4
                  <div class="right-element"><strong>Amazon France Logistique SAS</strong> 
                    <p>Site de Brétigny-sur-Orge</p>
                  </div>
                </div>
                <h3 className="mb-3">Formulaire de départ anticipé pour manque d'activité</h3>
                <Form>
                  <Form.Group controlId="employeelastnameVto" className="mb-3">
                    <Form.Label htmlFor="employeelastnameVto">Nom du salarié(e):</Form.Label>
                    <Form.Control
                      type="text"
                      name="employeelastnameVto"
                      value={formDataVto.employeelastnameVto}
                      onChange={handleChangeVto}
                      required
                      autoComplete="nope"
                    />
                  </Form.Group>
        
                  <Form.Group controlId="employeefirstnameVto" className="mb-3">
                    <Form.Label htmlFor="employeefirstnameVto">Prénom du salarié(e):</Form.Label>
                    <Form.Control
                      type="text"
                      name="employeefirstnameVto"
                      value={formDataVto.employeefirstnameVto}
                      onChange={handleChangeVto}
                      required
                      autoComplete="nope"
                    />
                  </Form.Group>
        
                  <Form.Group controlId="employeeLoginVto" className="mb-3">
                    <Form.Label htmlFor="employeeLoginVto">Login du salarié(e):</Form.Label>
                    <Form.Control
                      type="text"
                      name="employeeLoginVto"
                      value={formDataVto.employeeLoginVto}
                      onChange={handleChangeVto}
                      required
                      autoComplete="nope"
                    />
                  </Form.Group>
        
                  <Form.Group controlId="socioProfessionalCategoryVto" className="mb-3">
                    <Form.Label htmlFor="socioProfessionalCategoryVto">Catégorie:</Form.Label>
                    <Form.Control
                      as="select"
                      name="socioProfessionalCategoryVto"
                      value={formDataVto.socioProfessionalCategoryVto}
                      onChange={handleChangeVto}
                      placeholder="Select your socio"
                      required
                    >
                      <option value="">--</option>
                      <option value="interim">Intérimaire</option>
                      <option value="fulltime">Salarié(e)</option>
                    </Form.Control>
                  </Form.Group>
        
                  <Form.Group controlId="employerPropositionsVto" className="mb-3">
                    <Form.Label htmlFor="employerPropositionsVto">Je reconnais également avoir été informé et:</Form.Label>
                    <Form.Control
                      as="select"
                      name="employerPropositionsVto"
                      value={formDataVto.employerPropositionsVto}
                      onChange={handleChangeVto}
                      placeholder="Select your empoloyer props"
                      required
                    >
                      <option value="">--</option>
                      <option value="compensationVto">
                      VTO recup.
                      </option>
                      <option value="unpaidLeaveVto">
                      VTO sans solde.
                      </option>
                    </Form.Control>
                  </Form.Group>
        
                  <Form.Group controlId="employerNameVto" className="mb-3">
                    <Form.Label htmlFor="employerNameVto">Nom et Prénom pour Amazon France Logistique SAS:</Form.Label>
                    <Form.Control
                      type="text"
                      name="employerNameVto"
                      value={formDataVto.employerNameVto}
                      onChange={handleChangeVto}
                      required
                      autoComplete="nope"
                    />
                  </Form.Group>
        
                  <Form.Group controlId="emailVto" className="mb-3">
                    <Form.Label htmlFor="emailVto">Email pour Amazon France Logistique SAS:</Form.Label>
                    <Form.Control
                      as="select"
                      name="emailVto"
                      value={formDataVto.emailVto}
                      onChange={handleChangeVto}
                      required
                      ref={emailInputRefVto}
                      autoComplete="nope"
                    >
                      <option value="">Choisissez un email</option>
                      <option value="ory4-hrap@amazon.com">ory4-hrap@amazon.com</option>
                      <option value="adecco.dc1@onsite-solutions.fr">adecco.dc1@onsite-solutions.fr</option>
                    </Form.Control>
                    <small className="text-muted">Ou entrez votre propre email:</small>
                    <Form.Control
                      type="email"
                      name="emailVto"
                      value={formDataVto.emailVto}
                      onChange={handleChangeVto}
                      autoComplete="nope"
                    />
                    {formErrorVto && !formDataVto.emailVto && !formDataVto.customEmail && (
                      <div className="error-message">Choisissez un email ou saisissez le vôtre</div>
                    )}
                  </Form.Group>
        
                  <Form.Group controlId="employeeSignatureVto" className="inline-form-group">
                    <Form.Label className="form-label" htmlFor="employeeSignatureVto">Pour salarié(e):</Form.Label>
                    <SignatureCanvas
                      ref={employeeSignatureRefVto}
                      canvasProps={{
                        width: 500,
                        height: 200,
                        className: "employee-signature-canvas",
                      }}
                      id="employee-signature-canvas"
                    />
                    <div>
                      <Button
                        variant="outline-secondary"
                        onClick={handleClearEmployeeSignatureVto}
                      >
                        Effacer Signature
                      </Button>
                    </div>
                  </Form.Group>
        
                  <div class="wrapper">
                    <Button variant="primary" type="button" onClick={handleGeneratePDFVto}>
                      Générer mon document
                    </Button>
                    {/* Conditional rendering of the spinner */}
                    {loading && <Spinner animation="border"/>}   
                  </div>
                </Form>
        
                {/* Modal for success message */}
                <Modal show={showModalSuccessVto} onHide={handleCloseModalSuccessVto} className="form-modal">
                  <Modal.Header closeButton>
                    <Modal.Title>Email envoyé</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Un email avec une copie du document a été envoyé au responsable.</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModalSuccessVto}>
                      Fermer
                    </Button>
                  </Modal.Footer>
                </Modal>
        
                {/* Modal for error message */}
                <Modal show={showModalErrorVto} onHide={handleCloseModalErrorVto} className="form-modal">
                  <Modal.Header closeButton>
                    <Modal.Title>Email non envoyé</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Une erreur technique est survenue. Merci de réessayer ultérieurement.</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModalError}>
                      Fermer
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            } />
          </Routes>
        </div>
      </Router>
    </div>
  );
  
};

export default FormToPDF;
